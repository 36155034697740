<template>
	<b-card card-body-classes="table-full-width p-0" no-footer-line class="categories-table">
		<b-row class="px-0" align-h="between">
			<b-col class="text-left" align-self="center">
				<fg-input class="mb-2" v-if="current_list.length > 0">
					<el-input type="search" clearable
						prefix-icon="el-icon-search"
						style="max-width: 350px; padding-left: 7px;"
						:placeholder="this.t('Search records')"
						v-model="searchQuery"
						aria-controls="datatables" />
				</fg-input>
			</b-col>
		</b-row>
		<el-table v-if="rows.length > 0" :data="rows" ref="tableData" style="width: 100%">
			<template v-if="isp">
				<el-table-column :label="t('ISP Category id')" :min-width="50">
					<template slot-scope="scope">
						<span style="margin-left: 10px">{{ scope.row.isp_category_id }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="t('Status')" :min-width="70">
					<template slot-scope="scope">
						<category-switch :id="scope.row.isp_category_id" :visible_for_permissions='[{actions: ["PATCH"], path: "/isp-categories/.*"}]'/>
					</template>
				</el-table-column>
				<el-table-column :label="t('Categories Mapped')" :min-width="80">
					<template slot-scope="scope">
						<span v-for="(category, key) in tags(scope.row)" class="tag" :key="key">
							{{ t(category) }}
						</span>
					</template>
				</el-table-column>
				<el-table-column :label="t('Languages')" :min-width="80">
					<template slot-scope="scope">
						<span v-for="(language, key) in scope.row.isp_category_i18n" class="tag" :key="key">
							{{ getLanguageNameById(language.language_id) }}
						</span>
					</template>
				</el-table-column>
			</template>
			<template v-else>
				<el-table-column :label="t('Category name')" :min-width="100">
					<template slot-scope="scope">
						<span style="margin-left: 10px">{{ t(scope.row.isp_internal_category_id) }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="t('Type')" :min-width="100">
					<template slot-scope="scope">
						<span>{{ scope.row.category_type }}</span>
					</template>
				</el-table-column>
			</template>

			<el-table-column :label="t('Actions')" :width="120">
				<template slot-scope="scope">
					<el-tooltip :content="t('Edit')" :open-delay="250" :hide-after="1500">
						<n-button @click.native="handleEdit(scope.row)" class="m-1" type="warning" size="sm" round icon :visible_for_permissions='[{actions: ["PUT"], path: "/isp-categories/.*"}]'>
							<i class="now-ui-icons business_badge" />
						</n-button>
					</el-tooltip>
					<el-tooltip :content="t('Remove')" :open-delay="250" :hide-after="1500">
						<n-button v-if="isInternal(scope.row)" @click.native="handleDelete(scope.row)" class="m-1" type="danger" size="sm" round icon :visible_for_permissions='[{actions: ["DELETE"], path: "/isp-categories/.*"}]'>
							<i class="now-ui-icons ui-1_simple-remove" />
						</n-button>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column type="expand" width="1" align="left">
				<template slot-scope="props">
					<edit-isp-category  v-if="isp" :category="props.row" />
					<edit-internal-category v-else :id="props.row.isp_internal_category_id"  />
				</template>
			</el-table-column>
		</el-table>
		<empty-state v-else/>
		<div v-if="rows.length > 0" slot="footer" class="col-12 paginated-table-card__pagination-wrapper">
			<div class="paginated-table-card__pagination-item mb-4 mb-sm-0">
				<p class="card-category">{{t('Showing')}} {{from + 1}} {{t('to')}} {{to}} {{t('of')}} {{total}} {{t('entries')}}</p>
			</div>
			<div class="paginated-table-card__pagination-item order-3 order-sm-1">
				<span class="card-category pr-2">{{t('Results per page')}}:</span>
				<el-select class="select-primary paginated-table-card__perpage-select" v-model="pagination.perPage" placeholder="Per page">
					<el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item" />
				</el-select>
			</div>
			<div class="paginated-table-card__pagination-item order-2 order-sm-1 mb-4 mb-sm-0">
				<n-pagination class="pagination-no-border mb-0" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" />
			</div>
		</div>
	</b-card>
</template>

<script>
import { mapState } 			from 'vuex';
import   CategorySwitch 		from '@/components/IspServices/Configuration/Categories/Table/CategorySwitch';
import   EmptyState 			from '@/components/EmptyState';
import   Pagination 			from '@/components/Pagination';
import   EditInternalCategory   from '@/components/IspServices/Configuration/Categories/Table/EditInternalCategory';
import   EditIspCategory   		from '@/components/IspServices/Configuration/Categories/Table/EditIspCategory';
import languagesList from '@/mixins/languagesList';
import   _ 					    from 'lodash';

export default {
  mixins: [languagesList],
  components: {
		CategorySwitch,
		EditInternalCategory,
		EmptyState,
		EditIspCategory,
		'n-pagination' : Pagination,
	},
	props: {
		isp: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			checked_row: null,
			searchQuery: '',
			pagination: {
				perPage: 5,
				currentPage: 1,
				perPageOptions: [5, 10, 20, 50],
				total: 0
			},
		}
	},
	computed: {
		...mapState('ispServices/categoryManagement', ['isp_categories', 'categories']),

		current_list() {
			return this[`${this.isp ? 'isp_' : ''}categories`];
		},
		rows() {
			if(this.filtered_list.slice(this.from, this.to).length < 1) {
				this.pagination.currentPage = 1;
			}

			return this.filtered_list.slice(this.from, this.to);
		},
		to() {
			return this.total < this.from + this.pagination.perPage ? this.total : this.from + this.pagination.perPage;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.filtered_list.length;
		},
		filtered_list() {
			return this.isp 
				? this.current_list.filter(category => category[`isp_${this.isp ? '' : 'internal_'}category_id`].includes(this.searchQuery))
				: this.current_list.filter(category => this.t(category[`isp_${this.isp ? '' : 'internal_'}category_id`]).includes(this.searchQuery))
		}
	},
	methods: {
    	handleDelete(row) {
			this.$emit('handle-remove', row);
		},
		handleEdit(row) {
			if(_.isEqual(this.checked_row, row)) {
				this.checked_row = null;
			}
			else {
				this.$refs.tableData.toggleRowExpansion(this.checked_row, false);
				this.checked_row = row;
			}

			this.$refs.tableData.toggleRowExpansion(row);
		},
		isInternal(category) {
			return _.get(category, 'category_type') !== 'internal';
		},
		tags(isp) {
			const custom_categories = isp.custom_categories;
			const sm_categories     = isp.sm_categories;

            if (custom_categories) {
              return sm_categories ? sm_categories.concat(custom_categories) : custom_categories;
            } else {
              return sm_categories;
            }
		}
	},
}
</script>

<style lang="scss">
.categories-table {
  .disabled {
    position: relative;
  }
  div.el-table__expand-icon {
    display: none !important;
  }
  .tag {
    margin: 5px;
    border: 1px solid #e9e9eb;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    background-color: #f4f4f5;
    color: #909399;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
  }
}
</style>
