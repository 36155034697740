<template>
  <div>
    <h4>{{t('URL') + ' ' + this.inspected_url + ' ' + t('appears in the following lists')}}</h4>
    <div
      v-for="row in rows"
      :key="row.service"
      :closable="permission_granted">
        <p style="margin-top: 20px; margin-bottom: 20px;">
          <span v-if="row.list_type == 'allowed'">
            <svgicon
              original
              class="icon"
              icon="url-allow"
              width="17"
              height="17"
              style="margin-right: 10px;"
            />
            {{t('ISP allowed URLs')}}
          </span>
          <span v-else>
            <svgicon
              original
              class="icon"
              icon="url-forbid"
              width="15"
              height="15"
              style="margin-right: 12px;"
            />
            {{t('ISP forbidden URLs')}}
          </span>
          <el-tag class='button-new-tag'>{{t(row.service)}}</el-tag>
          <span class='clickable-span' v-if="permission_granted" @click="remove_from_list(row);">{{t('Remove from list')}}</span>
        </p>
    </div>
  </div>
</template>

<script>
import { PaginatedTable } from "@/components";
import { mapState, mapActions } from "vuex";
import Tags from "@/components/Tables/Formatters/Tags";
import permissions from '@/mixins/permissions'

export default {
  name: 'global-category-list',
  components: {
    PaginatedTable,
  },
  mixins: [permissions],
  data() {
    return {};
  },
  computed: {
    ...mapState("ispServices/categoryManagement", [
      "inspected_global_categories",
      "inspected_url",
    ]),
    rows() {
      return this.inspected_global_categories.map((category) => {
        return { service: category.value,
                 type: this.t(category.type),
                 list_type: category.type
        };
      });
    },
  },
  methods: {
    ...mapActions("ispServices/categoryManagement", ['remove_from_global_list']),
    remove_from_list(item) {
      this.remove_from_global_list({
        list_type: item.list_type,
        item: this.inspected_url,
        service: item.service
      });
    },
  },
};
</script>
<style lang="scss">
  .clickable-span {
    color: #f96332;
    cursor: pointer;
  }
  .button-new-tag {
    margin-left: 10px;
    margin-right: 10px;   
    line-height: 26px;
    background-color: #ffffff;
    border-radius: 50px;
    border-color: gray;
    color: gray;
  }
</style>