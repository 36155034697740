<template>
	<b-card class="px-1 mb-2">
		<h4 class="my-2">{{t('Check how a specific URL is categorized or filtered')}}</h4>
		<b-row class="align-items-baseline">
			<b-col md="12" xl="6">
				<fg-input v-model="url"
					name="url"
					:placeholder="this.t('Enter a valid url *')"
					v-validate="'url'"
					:visible_for_permissions='[{actions: ["POST"], path: "/isp-url-inspection"}, 
												{actions: ["POST"], path: "/isp-url-contribution"}, 
												{actions: ["POST"], path: "/isp-internal-categories-remove"}]'
					operator='OR'
					:error="errors.first('url')" />
			</b-col>
			<b-col md="5" xl="2" >
				<n-button style="min-width: 80px;" type="primary" size="md" round block @click.native="onSearch(url)" 
				:visible_for_permissions='[{actions: ["POST"], path: "/isp-url-inspection"}]'> 
					<span class="text-capitalize">{{t('search')}}</span>
				</n-button>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default 
{
	data() {
		return {
			url: '',
			sender_email: '',
			comment: '',
		}
	},
	computed: {
		...mapState('ispServices/categoryManagement', ['inspected_internal_categories', 'inspected_global_categories', 'inspected_url']),
		...mapGetters('settings', ['getLanguage']),
		lang(){
			return this.getLanguage + '-' + this.getLanguage
		}		
	},
	methods: {
		...mapActions('ispServices/categoryManagement', ['urlInspection', 'remove_from_global_list', 'remove_from_internal_category']),
		async onSearch(url){
			let isValidForm = await this.$validator.validateAll();
			if (isValidForm){
				try {
					if ( url.startsWith('http://'))
						url = url.replace('http://', '')
					if ( url.startsWith('https://'))
						url = url.replace('https://', '')
					await this.urlInspection(url)
				} catch (error) {
					this.url = ''
				}
			}
		},
		getError(fieldName) {
			return this.errors.first(fieldName);
		},
	},
}
</script>

<style lang="scss" scoped>
.form__height {
	margin-bottom: 2rem
}
</style>
