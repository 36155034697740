<template>
  <div>
    <b-card class="px-2">
      <template v-slot:header>
        <h4 class="title mt-4 mb-0" style="display: flex;">
          <svgicon class="icon mr-2" :icon="isp ? 'alerts-list' : 'item-categories'" width="20" height="20"/>
          <span>{{ title }}</span>
        </h4>
        <b-row style="align-items: baseline">
          <b-col md="9" xs="7">
            <h5 class="mt-4">{{ subtitle }}</h5>
          </b-col>
          <b-col md="3" xs="5">
            <n-button
              type="primary"
              size="md"
              round block
              v-b-modal.new-category
              @click.native="$emit('reset-modal', isp)"
              :visible_for_permissions='[{actions: ["POST"], path: "/isp-categories"}]'
            >
              <i><svgicon class="icon" icon="icon-new" /></i>
              {{ t("New") }}
            </n-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col cols="12" class="p-0">
          <categories-table @handle-remove="removeCategory" :isp="isp" />
        </b-col>
      </b-row>
      <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div v-if="!updating" style="cursor: pointer;display: inline;" @click="$emit('refresh')" >
          <i class="now-ui-icons arrows-1_refresh-69 ml-4" />
          {{ t('Update now') }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin ml-4"/>
          {{ t('Updating...') }}
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapState }       from 'vuex';
import { PaginatedTable } from '@/components';
import   CategoriesTable  from '@/components/IspServices/Configuration/Categories/Table/CategoriesTable';

export default 
{
  components: {
    PaginatedTable,
    CategoriesTable,
  },
  props: {
    title: String,
    subtitle: String,
    isp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      custom_category: '',
    }
  },
  computed: {
    ...mapState('ispServices/categoryManagement', ['updating'])
  },
  methods: {
    removeCategory(row) {
      this.$emit('handle-remove', { isp: this.isp, row })
    }
  },
}
</script>
