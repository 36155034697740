<template>
  <div
    class="p-3 mt-4"
    style="background-color: rgb(245 246 250 / 55%); border-radius: 5px"
  >
    <n-removeable-button-list size="md" round block :buttonList="getParsedCategories" @itemRemoved="handleCategoryRemoved"/>
    <b-row v-if="show_remove_button">
			<b-col md="6" xl="6" class="ml-auto">
				<n-button type="primary" size="md" round block @click.native="removeUrl" 
					:visible_for_permissions='[{actions: ["POST"], path: "/isp-internal-categories-remove"}]'> 
					<span class="text-capitalize">{{t('Uncategorize from all')}}</span>
				</n-button>
			</b-col>
		</b-row>
    <b-row class="mt-4">
      <b-col md="9" xl="9">
        <h4>{{ t('Has') + " " + this.inspected_url + " " + t('not been correctly categorized')}}?</h4>
        <p>{{ t('if the searched URL has not beed correctly categorized, you can ask for revision')}}</p>
      </b-col>
			<b-col md="3" xl="3" class="ml-auto">
				<n-button type='primary' class='custom-button' size="md" round block @click.native="openContributionPage()" 
					:visible_for_permissions='[{actions: ["POST"], path: "/isp-url-contribution"}]'> 
					<span>{{t('Ask for revision')}}</span>
				</n-button>
			</b-col>
    </b-row>
    
		<b-modal id="confirm-remove-url" @ok="removeUrlAll(inspected_url)" :title="inspected_url">
			{{t('Are you sure you want to exclude this URL from all the categories where is included?')}}
		</b-modal>

		<b-modal id="confirm-remove-category" @ok="removeCategory(selectedCategory)" :title="inspected_url">
      {{t('Are you sure you want to remove this category')}}?
			<p>{{t(this.selectedCategory.label)}}</p>
		</b-modal>

    <b-modal id="ask-for-revision" @ok.prevent="askRevision(inspected_url)" :title="t('Ask for revision')" :ok-title="t('accept')" :cancel-title="t('cancel')">
			<b-form @submit.prevent="">
        		<b-form-group class="form__height">
					<fg-input v-model="url"
						id="url"
						v-validate="'required|url'"
						name="url"
						:error="errors.first('url')"
						class="no-border no-height form-control-lg form__height"
						:placeholder="t('Url')"
						addon-left-icon="now-ui-icons ui-1_send"
						autocapitalize="none"
						style="font-size:1rem;" />
					<fg-input v-model="sender_email"
						id="email"
						v-validate="'required|email'"
						name="email"
						:error="errors.first('email')"
						class="no-border no-height form-control-lg form__height"
						:placeholder="t('Email')"
						addon-left-icon="now-ui-icons ui-1_email-85"
						autocapitalize="none"
						style="font-size:1rem;" />
					<fg-input v-model="comment"
						id="comment"
						name="comment"
						class="input-group no-border no-height form-control-lg hide-scrollbar form__height"
						:placeholder="t('Remarks')"
						textarea
						autocapitalize="none"
						style="font-size:1rem;" />
				</b-form-group>
			</b-form>
		</b-modal>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ButtonListRemoveable from '@/components/ButtonListRemoveable.vue';

export default {
  name: 'internal-category-list',
  components: {
    'n-removeable-button-list': ButtonListRemoveable
  },
  data() {
    return {
			url: '',
			sender_email: '',
			comment: '',
      selectedCategory: ''
    };
  },
  computed: {
	...mapGetters('settings', ['getLanguage', 'getRegionCode']),
    ...mapState("ispServices/categoryManagement", ["inspected_internal_categories", 'inspected_global_categories', "inspected_url"]),
	lang(){
		return this.getLanguage + '-' + this.getRegionCode
	},
    getParsedCategories() {
        return this.inspected_internal_categories.map(category => ({
            id: category.value,
            label: category.value,
            type: category.type,
            color: category.type == 'custom' ? '#775ed4' : '#3aab96'
          })
        )
    },
    show_remove_button() {
			return this.inspected_internal_categories.length > 0 || this.inspected_global_categories.length > 0;
		}
  },
  methods: {
    ...mapActions("ispServices/categoryManagement", ['remove_from_global_list', 'remove_from_internal_category']),
    handleCategoryRemoved(data) {
      this.selectedCategory = data;
      this.$bvModal.show('confirm-remove-category');
    },
    removeUrl() {
			if(this.inspected_url.length)	
				this.$bvModal.show('confirm-remove-url');
		},
		removeUrlAll(url){
			this.inspected_global_categories.forEach( item => {
				this.remove_from_global_list({
					list_type: item.type,
					item: url,
					service: item.value
				});
			});
			this.inspected_internal_categories.forEach( item => {
				this.remove_from_internal_category({
					category: item.value,
					type: item.type,
					item: url,
				});
			});
		},
		removeCategory(category){
			this.remove_from_internal_category({
				category: category.id,
				type: category.type,
				item: this.inspected_url,
			});
		},
		openContributionPage(){
			this.$bvModal.show('ask-for-revision');
		},
    ...mapActions('ispServices/categoryManagement', ['askForRevision']),
    async askRevision() {
			const isValidForm = await this.$validator.validateAll();
			if(isValidForm) {
				let url = this.url
				if ( url.startsWith('http://'))
					url = url.replace('http://', '')
				if ( url.startsWith('https://'))
					url = url.replace('https://', '')
				let payload = {
					action_type : 'revise',
					url         : url,
					sender_email: this.sender_email,
					language_id:  this.lang
				};

				if(!!this.comment) {
					payload.comment = this.comment
				} 
				
				this.askForRevision(payload).then( () => {
					this.$bvModal.hide('ask-for-revision');
				})
			}
		} 
  },
};
</script>
<style lang="scss">
.custom-button {
  background: white;
  color: #f96332;
  border: 1px solid #f96332;
  height: 80px;
}
</style>