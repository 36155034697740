<template>
  <b-card class="top-card">
      <b-row class="px-1">
          <b-col cols="12" xl="12">
              <search-section />
          </b-col>
          <b-col v-if="show_internal_list" xl="12" class="col-xxl-6">
			  <b-row>
				<b-col>
					<h4>{{ t('Internal and Custom Categories')}}</h4>
					<h4>{{ t('URL') + " " + this.inspected_url + " " + t('has been categorized as')}}:</h4></b-col>
				<b-col class="ml-auto col-md-4 col-xl-4">
					<span class="dot-purple mr-2"/><span>{{t('custom')}}</span>
					<span class="dot-green mr-2 ml-4"/><span>{{t('internal')}}</span>
				</b-col>
			  </b-row>
              <internal-categories-list />
          </b-col>
          <b-col v-if="show_global_list" xl="12" class="col-xxl-6">
              <h4>{{ t('Content Filter Lists')}}</h4>
              <global-categories-list />
          </b-col>
      </b-row>
  </b-card>
</template>

<script>
import   SearchSection from './SearchSection';
import   InternalCategoriesList from './InternalCategoriesList';
import   GlobalCategoriesList  from './GlobalCategoriesList';
import { mapState, mapActions }      from 'vuex';

export default {
	components: {
		SearchSection,
		InternalCategoriesList,
		GlobalCategoriesList

	},
	computed: {
		...mapState('ispServices/categoryManagement', ['inspected_url', 'inspected_global_categories']),

		show_internal_list() {
			return this.inspected_url.length > 0;
		},
		show_global_list() {
			return this.inspected_global_categories.length > 0 && this.inspected_url.length > 0;
		}
	},
	methods:{
		...mapActions('ispServices/categoryManagement', ['getCategories'])
	},
	created(){
		this.getCategories();
	}
}
</script>
<style lang="scss">
  .top-card > .card-body {
    padding: 0;
  }

.dot-purple {
  height: 12px;
  width: 12px;
  background-color: #775ed4;
  border-radius: 50%;
  display: inline-block;
} 

.dot-green {
  height: 12px;
  width: 12px;
  background-color: #3aab96;
  border-radius: 50%;
  display: inline-block;
} 
</style>
