<template>
  <span class="flex-container">
    <el-tag
      v-for="tag in buttonList"
      :key="tag.id"
      :closable="permission_granted"
      effect="plain"
      class="button-new-tag"  
      @close="handleClose(tag)">
      <span v-if="tag.color" :style="{ 'background-color': tag.color }" class="dot mr-2"/>
      <span style="color: gray; font-size: 1.4em; font-weight: 1 !important;">{{t(tag.label)}}</span>
    </el-tag>
  </span>
</template>
<script>

import permissions from '@/mixins/permissions'

export default {
  name: 'n-removeable-button-list',
  mixins: [permissions],
  props: {
    buttonList: {
      type: Array,
      default: () => []
    }
  },
  computed: {},
  methods: {
    handleClose(tag) {
      this.$emit('itemRemoved', tag);  
    }
  }
};
</script>
<style>
  .flex-container {
    display: flex;
    flex-direction: row;
    text-align: center;
  }
  .button-new-tag {
    margin-left: 10px;  
    line-height: 26px;
    background-color: #ffffff;
    border-radius: 50px;
    border-color: lightgray;
  }
  .el-tag .el-icon-close::before {
    color: gray;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
} 

  @media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}
</style>
