<template>
  <b-card class="px-1">
    <template v-slot:header>
      <h4 class="title mt-4 mb-0">
        <svgicon
          style="margin-right: 10px"
          original
          class="icon"
          :icon="`${action}-list-icon`"
          width="44"
          height="44"
        />
        <span>{{ t(title) }}</span>
      </h4>
      <product-attention-message
        v-if="ProductAttention"
        :title="ProductAttention.title"
        :showOnlyIfProductsExist="ProductAttention.products"
        :operator="ProductAttention.operator"
      />
      <product-attention-message
        v-if="RouterSecureAttention"
        :title="RouterSecureAttention.title"
        :showOnlyIfProductsExist="RouterSecureAttention.products"
        :operator="RouterSecureAttention.operator"
      />
    </template>
    <b-row class="px-1">
      <b-col cols="12">
        <h4 class="my-2">{{ t(subtitle) }}</h4>
        <b-row class="align-items-baseline">
          <b-col cols="9">
            <fg-input
              v-model="url"
              name="urlipdomain"
              :placeholder="$t('Enter a valid ip, url or domain *')"
              v-validate="{url: {require_protocol: false }, required: true}"
              :visible_for_permissions="[
                { actions: ['PUT'], path: get_path(action) },
              ]"
              :error="getError('urlipdomain')"
            />
          </b-col>
          <b-col cols="3" class="ml-auto">
            <n-button
              type="primary"
              size="md"
              :disabled="!!getError('urlipdomain')"
              :visible_for_permissions="[
                { actions: ['PUT'], path: get_path(action) },
              ]"
              round
              block
              @click.native="add_url(url)"
            >
              <span class="text-capitalize">{{ t("Add") }}</span>
            </n-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        class="p-2 mt-4"
        style="background-color: rgb(245 246 250 / 55%); border-radius: 5px"
      >
        <paginated-table
          :showHeader="false"
          :itemsPerPage="10"
          :rows="rows"
          :columns="columns"
          :actions="actions"
          :search="['url']"
          @item-remove="remove_from_list"
          v-model="search_field_value"
          :updating="updating"
          :serverpagination="search_pagination"
          @prev="handlePrevPage"
          @next="handleNextPage"
          @pagination="HandlePagination"
          :searchText="searchText"
        />
        <!-- ! uncomment when backend will support the GET request with query paramaters  -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { PaginatedTable } from "@/components";
import GeneralMixin from "@/components/AsmConfigurations/general/GeneralMixin";
import ServerSidePaginatedTable from "@/mixins/ServerSidePaginatedTable";
import ProductAttentionMessage from "@/components/ProductAttentionMessage.vue";

export default {
  mixins: [GeneralMixin, ServerSidePaginatedTable],
  components: {
    PaginatedTable,
    ProductAttentionMessage,
  },
  props: {
    storeNamespace: String,
    title: String,
    subtitle: String,
    action: String,
    value: {
      type: Array,
      default: () => [],
    },
    ProductAttention: {
      type: [Object, null],
    },
    RouterSecureAttention: {
      type: [Object, null]
    },
    searchText : {
      type: String,
      default : ''
    }
  },
  data() {
    return {
      url: "",
      columns: [
        {
          prop: "index",
          minWidth: 30,
        },
        {
          prop: "url",
          minWidth: 100,
        },
      ],
      actions: {
        minWidth: 30,
        fixed: "right",
        items: [
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            event: "item-remove",
            action: this.t("Remove"),
            confirm: false, visible_for_permissions: [{actions: ["PUT"], path: this.get_path(this.action)}]
          },
        ],
      },
      currentPage: 1,
      nextPage: 0,
      changingPage: false,
      isListUpdated: false,
    };
  },
  created() {
    this.search_field_name = "url";
  },
  methods: {
    refresh() {
      this.updating = true;
      this.$emit("get-list", this.action, this.query);

      this.$nextTick(() => {
        // will run after $emit is done
        if (this.changingPage) {
          this.currentPage += this.nextPage;
        }
        this.updating = false;
      })

    },
    add_url(item) {
      item = item.replace('http://', '').replace('https://','')
      if (item && !this.value.includes(item)) {
        let cleanField = true;
        this.cleanQueryAndShowFirstPage(cleanField);
        this.$emit("update_list", { action: "add", item: item, query: this.query });
      }
      this.$nextTick( () => {
        this.url = "";
        this.$validator.reset();
      })
    },
    remove_from_list(item) {
      this.$emit("update_list", { action: "del", item: item.row.url, query: this.self_query });
    },
    get_path(action) {
      if (action == "allow") {
        return "/isp-allowed-lists/.*";
      } else {
        return "/isp-forbidden-lists/.*";
      }
    },
    handlePrevPage() {
      if (this.query) {
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.nextPage = -1;
          this.changingPage = true;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.next_id) {
          this.nextPage = 1;
          this.changingPage = true;
          this.refresh();
        }
      }
    },
    HandlePagination(value) {
      this.changingPage = false;
      this.self_query.limit = value;
      let cleanField = false;
      this.cleanQueryAndShowFirstPage(cleanField);
      this.reload();
    },
    cleanQueryAndShowFirstPage(cleanField) {
      this.currentPage = 1;
      this.nextPage = 0;
      if (cleanField) {
        ["field_operator", "field_name", "field_value"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
      }
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.query[item]) delete this.query[item];
      });
    },
    handleSearch: _.debounce(function (value) {
      let cleanField = false;
      if (value) {
        this.query.field_operator = "sw";
        this.query.field_name = value.field;
        this.query.field_value = value.value;
      } else {
        cleanField = true;
      }
      this.cleanQueryAndShowFirstPage(cleanField);
      this.refresh();
    }, 500),
    reload() {
      if (this.self_query) {
        this.changingPage = false;
        this.query = this.self_query;
        this.nextPage = 0;
        this.refresh();
      }
    },
  },
  computed: {
    rows() {
      let index = 0;

      return this.value.map((url) => {
        index++;
        return Object.create(
          {},
          { url: { value: url }, index: { value: index } }
        );
      });
    },
    self_query: {
      get() {
        return _.cloneDeep(this.$store.getters[`${this.storeNamespace}/params_self_query`](this.action));
      },
      set(value) {
        this.$store.commit({type:`${this.storeNamespace}/set_self_query`, self_query: value, action: this.action });
      },
    },
    query: {
      get() {
        return _.cloneDeep(this.$store.getters[`${this.storeNamespace}/params_query`](this.action));
      },
      set(value) {
        this.$store.commit({type:`${this.storeNamespace}/set_query`, query: value, action: this.action });
      },
    },
    search_field_value: {
      get() {
        if (this.query && this.query.field_value)
          return { field: "account_id", value: this.query.field_value };
        else return { field: "account_id", value: "" };
      },
      set(newValue) {
        this.handleSearch(newValue);
      },
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.disabled {
  top: 0;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: transparent;
  cursor: not-allowed;
}
.title-list-icon {
  width: 48px;
  margin-right: 8px;
}
</style>
