<template>
  <b-row class="p-0" >
    <b-col class="p-0" cols="10" xl="9">
      <b-card card-body-classes="table-full-width" no-footer-line>
        <b-container  slot="header" class="col-12">
          <b-row class="pl-3" >
            <b-col cols="4" class="text-left p-0" align-self="center">
              <h4 class="mb-2 grey-title-text">{{t('ISP Category ID')}}:</h4>
            </b-col>
            <b-col cols="4" class="text-left" align-self="center">
              <fg-input v-model="category.isp_category_id" disabled />
            </b-col>
          </b-row>
          <h4 class="mb-2 mt-4 grey-title-text">{{t('Included categories')}}:</h4>
          <b-row class="align-items-center pl-3">
            <div class="sub-title mr-2">
              {{t('Add one or more categories to the ISP Category')}}
            </div>
            <el-autocomplete
              class="isp-category__autocomplete mx-2"
              popperClass="isp-category__dropdown"
              aria-describedby="addon-right addon-left"
              v-model="category_to_add"
              :fetch-suggestions="querySearch"
              :placeholder="t('Add category')"
              @select="handleSelect" />
            <b-col cols="12" class="mt-2 mb-4 p-0">
              <template v-if="tags">
                <div class="hr-grey mb-2"/>
                <el-tag
                  class="isp-category__tag"
                  :key="tag"
                  v-for="tag in tags"
                  closable
                  @close="handleClose(tag)">
                  {{ t(tag) }}
                </el-tag>
                <div class="hr-grey my-2"/>
              </template>
            </b-col>
            <b-col class="px-0" cols="10">
              <h4 class="mb-2 grey-title-text">{{t('Languages supported')}}</h4>
            </b-col>
            <b-col class="ml-auto" cols="2">
              <n-button @click.native="addLanguage" class="add-language-button">
                <i class="now-ui-icons ui-1_simple-add" />
              </n-button>
            </b-col>
          </b-row>
          <el-table :data="model.isp_category_i18n" ref="i18n_tableData" style="width: 100%">
            <el-table-column :label="t('ISP Category name')" :min-width="100">
              <template slot-scope="scope">
                <fg-input 
                  v-if="scope.$index === edit_lang_index || scope.row.new"
                  @input="checkChanges"
                  v-model="scope.row.isp_category_name"
                  style="max-width: 175px; margin-bottom: 0;"
                />
                <span v-else style="margin-left: 10px">{{ scope.row.isp_category_name }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="t('Language')" :min-width="100">
              <template slot-scope="scope">
                <el-autocomplete v-if="scope.row.new"
                  style="max-width: 175px;"
                  :fetch-suggestions="getAvailableLanguages"
                  class="isp-category__autocomplete"
                  popperClass="isp-category__dropdown"
                  size="large"
                  placeholder="Select language"
                  @input='onInputLang($event, scope.row)'
                  @select="onAutoCompleteLang($event, scope.row)"
                  v-model="scope.row.language_name">
                  <template slot-scope="{ item }">{{ item.name }}</template>
                </el-autocomplete>
                <el-autocomplete v-if="scope.$index === edit_lang_index"
                  style="max-width: 175px;"
                  :fetch-suggestions="getAvailableLanguages"
                  class="isp-category__autocomplete"
                  popperClass="isp-category__dropdown"
                  size="large"
                  placeholder="Select language"
                  @input='onInputLang($event, scope.row)'
                  @select="onAutoCompleteLang($event, scope.row)"
                  v-model="edit_lang_name">
                  <template slot-scope="{ item }">{{ item.name }}</template>
                </el-autocomplete>
                <span v-if="!scope.row.new && scope.$index !== edit_lang_index">
                  {{ getLanguageNameById(scope.row.language_id) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="t('Actions')" :width="150" fixed="right">
              <template slot-scope="scope">
                <el-tooltip :content="t('Edit')" :open-delay="250" :hide-after="1500">
                  <n-button
                    :disabled='scope.row.new'
                    @click.native="handleEdit(scope.$index, scope.row)"
                    class="m-1"
                    type="warning"
                    size="sm"
                    round icon>
                    <i class="now-ui-icons business_badge" />
                  </n-button>
                </el-tooltip>
                <el-tooltip :content="t('Remove')" :open-delay="250" :hide-after="1500">
                  <n-button
                    @click.native="handleDelete(scope.$index)"
                    class="m-1"
                    type="danger"
                    size="sm"
                    round icon>
                    <i class="now-ui-icons ui-1_simple-remove" />
                  </n-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </b-container>
      </b-card>
    </b-col>
    <b-col cols="2" xl="3" class="pt-3">
      <button
        style="min-width: 75px;"
        class="btn btn-primary btn-round btn-block btn-md"
        v-text="getLocalizedText('Save')"
        @click="updateIspCategory(model)" />
    </b-col>
  </b-row>
</template>

<script>
import languagesList from '@/mixins/languagesList';
import { mapActions, mapMutations, mapState } from 'vuex';
import _ from 'lodash';

export default {
  mixins: [languagesList],
  props: {
    category: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      category_to_add: '',
      edit_lang_index: '',
      edit_lang_name: '',
      parsed_model: null,
      model: {}
    }
  },
  created() {
    this.parsed_model = JSON.stringify(this.category);
    this.model = JSON.parse(this.parsed_model);
  },
  computed: {
    ...mapState('ispServices/categoryManagement', ['categories', 'isp_categories']),

    filtered_options() {
      const to_filter = this.model.isp_category_i18n.map(item => item.language_id);
      return this.languages.filter(item => to_filter.indexOf(item) < 0);
    },
    internal_categories() {
      const used_categories = _.flattenDeep(this.isp_categories.map(isp => {
        const custom_categories = isp.custom_categories;
        const sm_categories = isp.sm_categories;

        if (custom_categories) {
          return sm_categories ? sm_categories.concat(custom_categories) : custom_categories;
        } else {
          return sm_categories;
        }
      }));

      return this.categories
        .filter(item =>! used_categories.includes(item.isp_internal_category_id))
        .map(category => {
          return { name: category.isp_internal_category_id, value: this.$t(category.isp_internal_category_id) };
      });
    },
    tags() {
      const custom_categories = this.model.custom_categories;
      const sm_categories     = this.model.sm_categories;

      if (custom_categories) {
        return sm_categories ? sm_categories.concat(custom_categories) : custom_categories;
      } else {
        return sm_categories;
      }
    },
    remainInternalCategories(){
      return this.internal_categories.filter(
        internalCat => this.model.sm_categories
          &&  this.model.sm_categories.length
            ? !this.model.sm_categories.includes(internalCat.name)
            : true
      );
    },
  },
  methods: {
    ...mapActions('ispServices/categoryManagement', ['updateIspCategory']),
    ...mapActions('settings', ['setIsFormDirty']),
    checkChanges() {
      const dirty = this.parsed_model	!= JSON.stringify(this.model);
      this.setIsFormDirty(dirty);
    },
    onAutoCompleteLang(language, row) {
      row.language_id = language.value;

      if (row.new) {
        row.language_name = language.name;
      } else {
        this.edit_lang_name = language.name;
      }

      this.checkChanges();
    },
    onInputLang(value, row) {
      if (value === '') {
        row.language_id = '';
      }
    },
    addLanguage() {
      this.model.isp_category_i18n.push(
        {
          language_id: "",
          isp_category_name: "",
          language_name: "",
          new: true
        }
      );
      this.checkChanges();
    },
    handleSelect(item) {
        item = item.name;
        const model = _.cloneDeep(this.model);

        if(! _.has(model, 'custom_categories')) {
            model.custom_categories = [];
        }
        if(! _.has(model, 'sm_categories')) {
            model.sm_categories = [];
        }

        if(this.categories
          .find(category => category.isp_internal_category_id == item)
          .category_type == 'internal') {
            model.sm_categories.push(item);
        } else {
          model.custom_categories.push(item);
        }

        this.model = model;
        this.category_to_add = '';
        this.checkChanges();
    },
    handleClose(item) {
      this.model.sm_categories = this.model.sm_categories.filter(tag => tag != item);
      if(this.model.custom_categories) {
        this.model.custom_categories = this.model.custom_categories.filter(tag => tag != item);
      }
      this.checkChanges();
    },
    handleDelete(index) {
      this.model.isp_category_i18n.splice(index, 1);
      this.checkChanges();
    },
    querySearch(queryString, cb) {
      cb(
        queryString
          ? this.remainInternalCategories.filter(this.createFilter(queryString))
          : this.remainInternalCategories
      );
    },
    createFilter(queryString) {
      return (category) => {
        return (category.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleEdit(index, row) {
      this.edit_lang_index = index;
      this.edit_lang_name = this.getLanguageNameById(row.language_id);
      this.checkChanges();
    },
    getLocalizedText(text) {
      return this.t(text);
    },
    getAvailableLanguages(queryString, cb) {
      const query = queryString ? queryString.toLowerCase() : '';
      const usedLanguageIds = this.model.isp_category_i18n.map(item => item.language_id.toLowerCase());
      const results = this.supported_languages_list.reduce((filteredLanguages, option) => {
        if ((option.language_name.toLowerCase().includes(query) || option.language_id.toLowerCase().includes(query))
          && !usedLanguageIds.includes(option.language_id.toLowerCase())
        ) {
            let filteredLanguage = {
              name: option.language_name,
              value: option.language_id
            };
            filteredLanguages.push(filteredLanguage);
        }
        return filteredLanguages;
      }, []);
      cb(results);
    },
  }
}
</script>

<style lang="scss" scoped>
  .grey-title-text {
    color: #576c94 !important;
  }
</style>

<style lang="scss">
  .isp-category__tag {
    margin: 5px;
    border: 1px solid #e9e9eb;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    background-color: #f4f4f5;
    color: #909399;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
  }
  .isp-category__tag .el-tag__close, .el-tag.el-tag--default .el-tag__close {
    color: #909399 !important;
  }
  .isp-category__autocomplete {
    .el-input__inner {
      border-radius: 50px;

      &:focus {
        border-color: #f78d1e;
      }
    }
  }
  .isp-category__dropdown {
    width: auto !important;
    min-width: 175px;
  }
  .add-language-button,
  .add-language-button:hover,
  .add-language-button:active,
  .add-language-button:active:hover,
  .add-language-button:active:focus,
  .add-language-button:focus {
      background: #0166ff !important;
      padding: 10px 12px;
      border-radius: 5px;
      float: right;
      line-height: 10px;
  }
  .add-language-button i {
      font-size: 16px;
      font-weight: bold;
  }
</style>
