<template>
	<n-switch v-model="enabled" color="allot" :visible_for_permissions="this.visible_for_permissions"/>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Switch }                 from '@/components';

export default {
	components: {
		[Switch.name]: Switch,
	},
	props: {
		id: {
			type: String,
			required: true
		},
		visible_for_permissions: {
			type: Array,
			default: []
		}
	},
	computed: {
		...mapGetters('ispServices/categoryManagement', ['isp_category']),

		enabled: {
			get() {
				return this.isp_category(this.id).enabled;
			},
			set() {
				console.log(!this.isp_category(this.id).enabled);
				const payload = {
					id: this.id, 
					...this.isp_category(this.id),
					enabled: !this.isp_category(this.id).enabled,
				}
				console.log(payload);
				this.setStatus(payload);
			}
		}
	},
	methods: {
		...mapActions('ispServices/categoryManagement', ['setStatus'])
	},
}
</script>
