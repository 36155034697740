<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-container fuild style="max-width: 2560px;">
      <b-row class="category-management">
        <b-card>
          <tabs type="primary"
                v-model="active_tab"
                :vertical="$store.state.settings.mobile"
                centered
          >
            <tab-pane :label="this.t('Search URL')">
              <div slot="label" class="mb-2">
                <svgicon class="icon" icon="item-searchurl" width="20" height="20" />
              </div>
              <top-card />
            </tab-pane>
            <tab-pane :label="this.t('ISP Categories')" >
              <div slot="label" class="mb-2">
                <svgicon class="icon" icon="alerts-list" width="20" height="20" />
              </div>
              <categories :title="t(title.isp_categories)"
                          @refresh="getIspCategories"
                          @handle-remove="removeCategory"
                          @reset-modal="resetModal"
                          :subtitle="t(subtitle.isp_categories)"
                          isp />
            </tab-pane>
            <tab-pane :label="this.t('Internal and Custom Categories')" >
              <div slot="label">
                <svgicon class="icon" icon="item-categories" width="20" height="20" />
              </div>
              <categories :title="t(title.internal_and_custom)"
                          @refresh="getCategories"
                          @handle-remove="removeCategory"
                          @reset-modal="resetModal"
                          :subtitle="t(subtitle.internal_and_custom)" />
            </tab-pane>
          </tabs>
        </b-card>
        <b-modal
          id="new-category"
          @ok="createCategory"
          :ok-disabled='disableSubmit'
          :title="modal.title"
          :ok-title="t('accept')"
          :cancel-title="t('cancel')">
          <fg-input
            v-model="modal.category_id"
            :placeholder="modal.id_placeholder"
            name="category_id"
            class="no-border no-height"
            addon-left-icon="now-ui-icons business_badge"
            inputClasses="category-management__custom-input isp-category-id__input"
            v-validate="modelValidations.categoryId"
            :error="getError('category_id')"
          />
          <template v-if="modal.isp">
            <div class="mt-4"><label>{{t('Select language')}}:</label></div>
            <el-autocomplete
              style="width: 100%;"
              :fetch-suggestions="querySearchLanguage"
              class="isp-category__autocomplete isp-category-modal__autocomplete"
              popperClass="isp-category__dropdown"
              size="large"
              :placeholder="modal.language_placeholder"
              @select="onAutoCompleteLang"
              @input='onInputLang($event)'
              v-model="lang_to_add.language_name">
              <template slot-scope="{ item }">{{ item.name }}</template>
            </el-autocomplete>
            <div class="mt-2"><label>{{t('Introduce the category name for that specific language')}}:</label></div>
            <fg-input
              v-model="lang_to_add.isp_category_name"
              :placeholder="modal.name_placeholder"
              name="isp_category_name"
              inputClasses="category-management__custom-input"
              v-validate="'required|max:255'"
              :error="getError('isp_category_name')"
            />
          </template>
        </b-modal>
        <b-modal
          id="confirm-remove"
          @ok="remove"
          :title="t('Delete category')"
          :ok-title="t('accept')"
          :cancel-title="t('cancel')">
          {{t('Are you sure you want to delete this category?')}}
        </b-modal>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { TabPane, Tabs }  from '@/components';
import   languagesList    from '@/mixins/languagesList';
import   TopCard          from '@/components/IspServices/Configuration/Categories/TopSection/TopCard';
import   Categories       from '@/components/IspServices/Configuration/Categories/Categories';
import { mapActions }     from 'vuex';
import { noSymbolsRegExp } from "../../../util/regexConsts";
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default
{
  name: 'category-management',
  mixins: [languagesList],
  components: {
    TabPane,
    Tabs,
    TopCard,
    Categories,
    PageTitleCard
  },
  data() {
    return {
      active_tab:null,
      lang_to_add: {
        language_id: '',
        language_name: '',
        isp_category_name: '',
      },
      category_to_remove: null,
      modal: {
        isp: false,
        title: '',
        id_placeholder: '',
        name_placeholder: '',
        language_placeholder: '',
        category_id: '',
      },
      modelValidations: {
        categoryId: {
          required: true,
          regex: noSymbolsRegExp,
          max: 79,
        }
      },
      title: {
        isp_categories: 'ISP Categories',
        internal_and_custom: 'Internal and Custom Categories'
      },
      subtitle: {
        isp_categories: 'Map Internal and Custom Categories to ISP Categories. From this section is possible to create, edit or remove ISP Caterogies.',
        internal_and_custom: 'Manage internal and custom categories. From this screen it is possible to create, edit or remove existing categories. Notice that only custom categories can be removed'
      }
    }
  },
  created() {
    this.getIspCategories();
    this.getCategories();
  },
  computed: {
    disableSubmit() {
      return !!this.errors.items.length;
    },
  },
  methods: {
    ...mapActions('ispServices/categoryManagement', [
      'getIspCategories',
      'getCategories',
      'createIspCategory',
      'createCustomCategory',
      'deleteIspCategory',
      'deleteInternalCategory',
      'setInspectedUrl'
    ]),

    onAutoCompleteLang(language) {
      this.lang_to_add.language_name = language.name;
      this.lang_to_add.language_id = language.value;
    },
    onInputLang(value) {
      if (value === '') {
        this.lang_to_add.language_id = '';
      }
    },
    resetModal(isp) {
      this.lang_to_add  = {
        language_id: 'en-US',
        isp_category_name: '',
        language_name: 'English'
      };
      this.modal.isp                  = isp;
      this.modal.title                = isp ? this.t('New ISP Category') : this.t('New category name');
      this.modal.id_placeholder       = isp ? this.t('ISP Category ID')  : this.t('MyCustomCategory');
      this.modal.name_placeholder     = this.t('ISP Category name');
      this.modal.language_placeholder = this.t('Select language');
      this.modal.category_id          = '';
    },

    async createCategory(bvModalEvt) {
      bvModalEvt.preventDefault();
      const isValidForm = await this.$validator.validateAll();
      if (!isValidForm) return false;
      this.$bvModal.hide('new-category')
      delete this.lang_to_add.language_name;
      const isp = { id: this.modal.category_id, language: this.lang_to_add };
      this.modal.isp
        ? this.createIspCategory(isp)
        : this.createCustomCategory(this.modal.category_id);
    },
    removeCategory(category) {
      this.category_to_remove = category;
      this.$bvModal.show('confirm-remove');
    },
    remove() {
      const category = this.category_to_remove;
      category.isp
        ? this.deleteIspCategory(category.row.isp_category_id)
        : this.deleteInternalCategory(category.row.isp_internal_category_id);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
  },
  beforeRouteLeave: function(to, from, next) {
    this.setInspectedUrl('');
    next();
  }
}
</script>


<style lang="scss" scoped>
::v-deep .nav-link{
  width: 120px;
  height: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.white-content .category-management__custom-input {
  color: black !important;
  border-color: rgba(170, 170, 170, 0.5) !important;

  &::placeholder {
    color: rgba(170, 170, 170, 0.7);
  }

  &:focus {
    background-color: rgba(170, 170, 170, 0.1) !important;
    border-color: rgba(170, 170, 170, 0.7) !important;
  }
}
.isp-category-id__input{
  padding: 11px !important;
}
.isp-category-modal__autocomplete .el-input__inner {
  background-color: #fa7a50;
  color: #fff;
  border-color: transparent;

  &::placeholder {
    color: #fff;
  }
}
</style>
