<template>
  <b-row>
    <b-col cols="12" class="col-xxml-6">
      <global-list
        v-model="black_list"
        storeNamespace="ispServices/forbiddenAllowedList"
        :title="t('URLS THAT DON\'T BELONG TO THIS CATEGORY')"
        :subtitle="t('Add URLs or domains that don\'t belong to this category')"
        action="block"
        @update_list="(data) => update_black_list({...data, query})"
        @get-list="handle_update_list"
      />
    </b-col>
    <b-col cols="12" class="col-xxml-6">
      <global-list
        v-model="white_list"
        storeNamespace="ispServices/forbiddenAllowedList"
        :title="t('URLS THAT BELONG TO THIS CATEGORY')"
        :subtitle="t('Add URLs or domains that belong to this category')"
        action="allow"
        @update_list="(data) => update_white_list({...data, query})"
        @get-list="handle_update_list"
      />
    </b-col>
  </b-row>
</template>

<script>
import GlobalList from "@/components/IspServices/Services/ForbiddenAllowedLIst/GlobalList";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  components: {
    GlobalList,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      query: {
        sort: "asc",
        limit: 10,
      },
    };
  },
  async created() {
    this.setCurrentInternalCategoryId(this.id);
    await Promise.all([
      this.get_black_list({ categoryId: this.id, query: this.query }),
      this.get_white_list({ categoryId: this.id, query: this.query }),
    ]);
  },
  methods: {
    ...mapMutations("ispServices/forbiddenAllowedList", [
      "setCurrentInternalCategoryId",
    ]),
    ...mapActions("ispServices/forbiddenAllowedList", [
      "get_black_list",
      "get_white_list",
      "update_black_list",
      "update_white_list",
    ]),
    handle_update_list(list_type, query) {
      if (list_type === "allow") this.get_white_list({ query });
      else this.get_black_list({ query });
    },
  },
  computed: {
    //   ! update
    ...mapGetters("ispServices/forbiddenAllowedList", [
      "black_list",
      "white_list",
    ]),
  },
};
</script>
